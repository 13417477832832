/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, meta, keywords, title, image }) => {
  var SEOURL =""
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    SEOURL = window.location.href
  }

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  console.log("image", process.env.BACKEND_URL + `${image}`)
  const metaImage =
    image !== ""
      ? process.env.BACKEND_URL + `${image}` ||
        `https://admin.dimensionsfrance.com/uploads/dfrance_logo_28045047e7.png`
      : `https://admin.dimensionsfrance.com/uploads/dfrance_logo_28045047e7.png`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: site.siteMetadata.keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          itemprop: `image`,
          content: metaImage,
        },
        { property: "og:image:width", content: "200" },
        { property: "og:image:height", content: "200" },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: SEOURL,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.siteName,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.siteName,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
